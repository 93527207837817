// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export function toApiUrl(api: string): string {
  return `https://${api}.appsync-api.us-east-1.amazonaws.com/graphql`;
}

export const environment = {
  aws_cognito_identity_pool_id:
    'us-east-1:24465326-396c-474b-b1a4-e15d6fd06ef2',
  aws_user_pools_id: 'us-east-1_UrDq7sHID',
  aws_user_pools_web_client_id: '21jftof3rh80proh75lkui3pot',
  production: false,
  userApi: toApiUrl('swdkzxoni5dtjhshka6pukshb4'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
