import { Injectable, NgZone } from '@angular/core';

import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

import {
  CognitoUserInterface,
  AuthState,
  AuthStateHandler,
  onAuthUIStateChange,
} from '@aws-amplify/ui-components';

import {
  AuthStateEvent,
  User,
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authStateEvent$:
    BehaviorSubject<AuthStateEvent | null>
    = new BehaviorSubject(null);

  public user$: BehaviorSubject<User | null> = new BehaviorSubject(null);

  constructor(private ngZone: NgZone) { }

  public authStateHandler: AuthStateHandler = (
    nextAuthState: AuthState,
    data?: object) => {
    // console.log('authStateHandler()');
    // console.dir(nextAuthState);
    // console.dir(data);
    this.ngZone.runTask(() => {
      if (nextAuthState === 'signedin') {
        const cognitoUser: CognitoUserInterface = data as CognitoUserInterface;
        const { username } = cognitoUser;
        const { attributes } = data as any;
        const {
          email,
          email_verified: emailVerified,
          family_name: familyName,
          given_name: givenName,
          phone_number: phoneNumber,
          phone_number_verified: phoneNumberVerified,
          sub: subjectIdentifier,
        } = attributes;
        const user: User = {
          email,
          emailVerified,
          familyName,
          givenName,
          phoneNumber,
          phoneNumberVerified,
          subjectIdentifier,
          username,
        };
        this.user$.next(user);
      } else {
        this.user$.next(null);
      }
      const authStateEvent: AuthStateEvent = {
        nextAuthState,
        cognitoUser: data as CognitoUserInterface,
      };
      this.authStateEvent$.next(authStateEvent);
    });
  }

  public init(): void {
    onAuthUIStateChange(this.authStateHandler);
  }

  public logout(): void {
    Auth.signOut();
  }
}
