import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from '@aws-amplify/core';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const {
  aws_cognito_identity_pool_id,
  aws_user_pools_id,
  aws_user_pools_web_client_id,
} = environment;

// https://docs.amplify.aws/lib/storage/getting-started/q/platform/js
//   #manual-setup-import-storage-bucket

const awsconfig = {
  Auth: {
    identityPoolId: aws_cognito_identity_pool_id,
    region: 'us-east-1',
    userPoolId: aws_user_pools_id,
    userPoolWebClientId: aws_user_pools_web_client_id,
  },
};

Amplify.configure(awsconfig);
