<div
  class="app-main">
<app-toolbar
  (clickNext)="onClickNext()"
  [disabledHome$]="disabledHome$"
  [disabledLogin$]="disabledLogin$"
  [disabledProfile$]="disabledProfile$"
>
</app-toolbar>
<router-outlet>
</router-outlet>
<app-footer>
</app-footer>
</div>