<mat-toolbar>
<!--
<button
  mat-button>
<mat-icon>
arrow_back
</mat-icon>
</button>
<button
  mat-button>
<mat-icon>
menu
</mat-icon>
</button>
-->
<button
  [disabled]="disabledHome$ | async"
  mat-button
  routerLink="/"
  title="Home">
<mat-icon>
home
</mat-icon>
</button>
<span
  class="app-spacer">
</span>
<span
  class="app-toolbar-title">
Skipper&trade; Host
</span>
<span
  class="app-spacer">
</span>
<button
  [disabled]="disabledProfile$ | async"
  routerLink="/profile"
  mat-button
  title="Profile">
<mat-icon>
perm_identity
</mat-icon>
</button>
<button
  [disabled]="disabledLogin$ | async"
  routerLink="/login"
  mat-button
  title="Login">
<mat-icon>
login
</mat-icon>
</button>
<button
  (click)="onClickNext($event)"
  mat-button
  title="Next">
<mat-icon>
arrow_forward
</mat-icon>
</button>
</mat-toolbar>
