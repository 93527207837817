import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { User } from './interfaces';
import { AuthService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
  implements OnDestroy, OnInit
{
  public disabledHome$ = new BehaviorSubject<boolean>(true);
  public disabledLogin$ = new BehaviorSubject<boolean>(true);
  public disabledProfile$ = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];
  private urlAfterRedirects: string = null;
  private user: User = null;

  constructor(
    private authService: AuthService,
    private router: Router) { }

  public ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((event => this.onRouterEvent(event))));
    this.subscriptions.push(this.authService.user$.subscribe(
      user => this.onUser(user)));
    this.authService.init();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onClickNext() {
    const url: string = this.router.url;
    if (url === '/') {
      this.router.navigateByUrl('/public-domain');
    } else if (url === '/public-domain') {
      this.router.navigateByUrl('/blogs');
    } else if (url === '/blogs') {
      this.router.navigateByUrl('/privacy-policy');
    } else {
      this.router.navigateByUrl('/');
    }
  }

  // private methods

  private onRouterEvent(event: any) {
    const routerEvent: any = event.routerEvent;
    if (!routerEvent) {
      return;
    }
    const { urlAfterRedirects } = routerEvent;
    this.urlAfterRedirects = routerEvent.urlAfterRedirects;
    this.disabledHome$.next(urlAfterRedirects === '/');
    this.disabledLogin$.next(urlAfterRedirects === '/login');
    this.updateDisabledProfile();
  }

  private onUser(user: User): void {
    this.user = user;
    this.updateDisabledProfile();
  }

  private updateDisabledProfile() {
    const disabledProfile: boolean
      = this.urlAfterRedirects === '/profile'
      || !this.user;
    this.disabledProfile$.next(disabledProfile);
  }
}
