import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent
{
  @Input()
  public disabledHome$: Observable<boolean>;

  @Input()
  public disabledLogin$: Observable<boolean>;

  @Input()
  public disabledProfile$: Observable<boolean>;

  @Output()
  public clickNext = new EventEmitter<any>();

  public onClickNext(event: any) {
    this.clickNext.emit(event);
  }
}
