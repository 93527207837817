<mat-toolbar>
<!--
<button
  mat-button>
<mat-icon>
arrow_back
</mat-icon>
</button>
-->
<span
  class="app-spacer">
</span>
<span
  class="app-footer-copyright">
&copy; 2020
<a
  href="https://www.SloopSoft.com/"
  target="_blank">
SloopSoft Inc</a>
/
<a
  routerLink="/privacy-policy">
Privacy Policy</a>
</span>
<span
  class="app-spacer">
</span>
<!--
<button
  mat-button>
<mat-icon>
arrow_forward
</mat-icon>
</button>
-->
</mat-toolbar>
